<template>
  <tbody class="bg-white divide-y divide-gray-100">
    <Row
        v-for="label in dataTable"
        :key="label.id"
        :label="label"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteLabelModal', label.id)"
        @handleOpenEditItemModal="$emit('handleOpenEditLabelModal', label)"
        @handleSetSelectedItem="handleSetSelectedItem"
        :selectedItem="String(selectedItem)"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import Row from '@/components/Label/Table/TableBody/Row/Row';

export default {
  name: 'TableBody',
  components: {
    EditActions,
    Row
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  methods: {
    handleSetSelectedItem(labelId) {
      this.selectedItem = labelId;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
