<template>
  <div class="w-full my-4" v-if="!loading">
    <DeleteItemModal
        v-if="showModalDeleteModal"
        :title="$t('label.deleteLabelModal.title')"
        :description="$t('label.deleteLabelModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteLabel"
    />
    <LabelCreateModal
        v-if="showCreateEditModal"
        :header="$t('label.createLabel')"
        :labelColors="labelColors"
        @handleCloseModal="closeCreateModal"
        @handleConfirm="createLabel"
        :labelDefaultColor="labelDefaultColor"
        :errorForm="errorForm"
        :formData="formData"
    />
    <div class="border-b">
      <DataTableSearch @handleSearchTable="handleSearch" />
      <Table
          @handleSort="handleSort"
          @handleOpenDeleteLabelModal="handleOpenDeleteLabelModal"
          @handleOpenEditLabelModal="handleOpenEditLabelModal"
          :dataTable="dataTable"
      />
      <Pagination :count="count" @handlePaginate="handlePaginate" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Pagination from '@/components/Main/Pagination/Pagination';
import handleTableSort from '@/helpers/handleTableSort';
import Table from '@/components/Label/Table/Table';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { EventBus } from '@/eventBus/event-bus';
import LabelCreateModal from '../../../components/Modals/LabelCreateModal/LabelCreateModal';


export default {
  name: 'LabelsTable',
  components: {
    LabelCreateModal,
    DataTableSearch,
    Pagination,
    Table,
    DeleteItemModal
  },

  data() {
    return {
      loading: false,
      labelsList: [],
      dataTable: [],
      isSearched: false,
      count: 1,
      showModalDeleteModal:false,
      selectedLabelId:'',
      showCreateEditModal:false,
      labelColors:[
        { id:1, name:'bg-labelGreen' },
        { id:2, name:'bg-labelYellow' },
        { id:3, name:'bg-labelOrange' },
        { id:4, name:'bg-labelRed' },
        { id:5, name:'bg-labelPurple' },
        { id:6, name:'bg-labelBlue' },
        { id:7, name:'bg-labelSky' },
        { id:8, name:'bg-labelLime' },
        { id:9, name:'bg-labelPink' },
        { id:10, name:'bg-labelBlack' }
      ],
      labelDefaultColor:   { id:11, name:'bg-labelGray' },
      errorForm:{},
      formData: {}
    }
  },
  computed: {
    ...mapGetters(['labels', 'createLabelSuccess', 'createLabelError', 'labelResponseMessage', 'deleteLabelSuccess', 'deleteLabelError', 'updateLabelSuccess', 'updateLabelError'])
  },
  watch: {
    labels(newValue) {
      this.labelsList = newValue;
      this.loading = false;
    },
    labelsList(newValue) {
      if (newValue){
        this.count = newValue.length;
        this.dataTable = newValue.slice(0, 30);
      }
    },
    createLabelSuccess(value){
      value && this.closeCreateModal()
      this.errorForm = {}
    },
    createLabelError(value){
      if (value) {
          this.errorForm = this.labelResponseMessage.errors
          this.toastMessage(this.labelResponseMessage.message, 'error');
        }
    },
    updateLabelSuccess(value){
      value && this.closeCreateModal()
      this.errorForm = {}
    },
    updateLabelError(value){
      if (value) {
        this.errorForm = this.labelResponseMessage.errors
        this.toastMessage(this.labelResponseMessage.message, 'error');
      }
    },
    deleteLabelSuccess() {
      this.showModalDeleteModal = false;
      this.toastMessage(this.labelResponseMessage, 'success');
    }

  },
  mounted() {
    this.getLabels();
    EventBus.$on('handleAddNewWithModal', () => this.createLabelModal());

  },
  methods: {
    ...mapActions(['getLabels', 'fetchDeleteLabel', 'fetchCreateLabel', 'fetchUpdateLabel' ]),
    toggleOpen() {
      this.isSearched = false;
    },
    handleSearch(value) {
      this.isSearched = true;
      this.dataTable = this.labelsList.filter((label) => {
        if (label.title.toLowerCase().includes(value)) {
          return label;
        }
      })
      this.dataTable = this.dataTable.slice(0, 30);
    },
    handlePaginate(page, defaultPageSlice) {
      const copyProducts = [...this.labelsList];
      this.dataTable = copyProducts.slice(page, defaultPageSlice);
    },
    handleSort(key, sorting) {
      handleTableSort(this, key, sorting);
    },
    handleOpenDeleteLabelModal(labelId) {
      this.selectedLabelId = labelId;
      this.showModalDeleteModal = true;
    },
    handleCloseModal(){
      this.showModalDeleteModal = false;
    },
    deleteLabel(){
      this.fetchDeleteLabel(this.selectedLabelId)
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    },
    handleOpenEditLabelModal(val){
      this.formData = val
      this.selectedLabelId = val.id
      this.showCreateEditModal = true
    },
    createLabelModal(){
      this.showCreateEditModal = true
      this.errorForm = {}
    },
    createLabel(value){
      if (this.selectedLabelId!==''){
        this.fetchUpdateLabel({ id:this.selectedLabelId, form:value })
      } else {
        this.fetchCreateLabel({ form:value })
      }
    },
    closeCreateModal(){
      this.showCreateEditModal=false
      this.formData = {}
      this.selectedLabelId = ''
    }
  }

}
</script>

<style scoped>

</style>
