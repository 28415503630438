<template>
  <tr @mouseover="mouseOver(true)" @mouseleave="mouseLeave(false)">
    <td class="py-3 text-sm text-gray-500 md:table-cell text-center pl-44">
      <span class="">{{ label.title }}</span>
    </td>
    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right flex justify-end">
      <p  class="w-48p h-32p rounded hover:bg-opacity-75 cursor-pointer" :class="label.color">
      </p>
    </td>
    <td class="hidden xl:table-cell px-8 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
      {{ label.created_at|parseDateMain }}
    </td>
    <td class="pr-4">
      <EditActions
          v-if="showEditable || (selectedItem === String(label.id))"
          :item="label"
          itemType="label"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleOpenEditItemModal = "handleOpenEditItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Label/EditActions/EditActions';
import moment from 'moment';

export default {
  name: 'Row',
  components: {
    EditActions
  },
  props: {
    label: {
      type: Object,
      required: true
    },
    selectedItem: String
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(labelId) {
      this.$emit('handleOpenDeleteItemModal', labelId);
    },
    handleOpenEditItemModal(labelId) {
      this.$emit('handleOpenEditItemModal', labelId);
    },
    setDateValue(value) {
     return moment(value).format('YYYY-MMM-DD');
    }
  }
}
</script>

<style scoped>

</style>
