<template>
  <thead class="border-t border-gray-200 border-b border-gray-200 bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
  <tr>
    <th class="whitespace-nowrap sm:w-300p xs:w-300p  px-6 py-3">
      <div class="flex justify-end cursor-pointer" @click="handleSort('title')">
        <div class="truncate block relative flex items-center flex-start pr-6 font-medium">
          {{ $t('label.fields.title') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'title'" :arrowUp="!arrowSortUp['title']" />
        </div>
      </div>
    </th>
    <th class="hidden xl:table-cell">
      <div class="flex justify-end cursor-pointer">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('label.fields.color') }}
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap hidden xl:table-cell pr-4">
      <div class="flex justify-end w-full cursor-pointer" @click="handleSort('created_at')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('label.fields.created_at') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'created_at'" :arrowUp="!arrowSortUp['created_at']" />
        </div>
      </div>
    </th>
    <th class="w-40p" />
  </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHead',
  components: {
    ArrowSolidIcon
  },
  data() {
    return {
      arrowSortUp: {
        title: true,
        created_at: true
      },
      selectedSortingKey: 'title'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    }
  }
}
</script>

<style scoped>

</style>
